import React, { Component } from 'react';

class Alert extends Component {

    state = { show: false, message: '', theme: 'red' }

    hide = () => {
        this.setState({ show: false, message: '' })
    }

    // show = (message) => {
    //     this.setState({ show: true, message: message })
    // }

    componentDidMount() {
        this.setState({ show: this.props.show, message: this.props.message, theme: this.props.theme })
    }

    componentWillReceiveProps(props) {
        this.setState({ show: props.show, message: props.message, theme: this.props.theme })
      }



    renderAlert() {
        const className = `mb-6 -mt-3 bg-${this.state.theme}-100 border border-${this.state.theme}-400 text-${this.state.theme}-700 px-4 py-3 rounded relative`
        return (
            <div className={className} role="alert">
                <strong className="font-bold"></strong>
                <span className="block sm:inline">{this.state.message}</span>
                <span onClick={this.hide} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg className={`fill-current h-6 w-6 text-${this.state.theme}-500`} role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                </span>
            </div>
        );
    }

    render() {

        if (!this.state.show) {
            return <div></div>;
        }

        // setTimeout(() => {
        //    this.hide()
        // }, 3000);

        return this.renderAlert();

    }
}

export default Alert;
