import React, { Component } from 'react';

class Logo extends Component {
    render() {
        return (
            <div>
                <img className="h-6" alt="Busha Pay" src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1558958812/busha_pay/bushapay.png" />
            </div>
        );
    }
}

export default Logo;