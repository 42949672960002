import React from 'react';
import FaucetForm from './FaucetForm';
import Logo from './Logo';
import pay from '../api/pay';

class App extends React.Component {
	processCoins = async (amount, currency, address, onSuccess, onError) => {
		try {
			const response = await pay.post('/deposits/dispense', {
				amount,
				currency,
				address,
			});
			onSuccess(response.data.message);
		} catch (err) {
			onError('An error occured');
		}
	};

	render() {
		return (
			<div className="h-screen overflow-hidden flex flex-col items-center justify-center">
				<Logo></Logo>
				<FaucetForm onProcessCoins={this.processCoins}></FaucetForm>
			</div>
		);
	}
}

export default App;
