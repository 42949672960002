import React, { Component } from 'react';
import Alert from './Alert'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class FaucetForm extends Component {

    state = { amount: '', currency: 'BTC', address: '', loading: false, show_alert: false, alert_message: '' }

    onSuccess = message => {
        this.setState({ amount: '', currency: 'BTC', address: '', loading: false, show_alert:true, alert_message: message, theme: 'teal' })
    }

    onError = message => {
        this.setState({ loading: false, show_alert:true, alert_message: message, theme: 'red' })
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        if (this.state.address === '') {
            this.setState({show_alert:true, alert_message: "Enter a valid address"})
            return
        }

        if (this.state.amount === '') {
            this.setState({show_alert:true, alert_message: "Enter a valid amount"})
            return
        }

        this.setState({ loading: true })
        this.props.onProcessCoins(this.state.amount, this.state.currency, this.state.address, this.onSuccess, this.onError);
    }



    render() {
        const { loading } = this.state;

        return (
            <div className="w-1/4">
                <form onSubmit={this.onFormSubmit} className="max-w-xl m-4 p-8 bg-white rounded shadow-xl">
                    <p className="mb-6 text-gray-800 font-bold">Get Free Test Coins</p>
                    {this.state.show_alert ? <Alert show={this.state.show_alert} message={this.state.alert_message}  theme="red"/> : ''}
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Enter Address
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={this.state.address}
                                onChange={e => this.setState({ address: e.target.value })}
                                type="text"
                                placeholder="37u6PHBSeKgFVjJ4cg7oEiELFGU4YhjBmi"
                            />
                            <p className="text-gray-600 text-xs italic" style={{ "display": "none" }}>Please enter a valid amount</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Enter Amount
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={this.state.amount}
                                onChange={e => this.setState({ amount: e.target.value })}
                                type="text"
                                placeholder="0.05"
                            />
                            <p className="text-gray-600 text-xs italic" style={{ "display": "none" }}>Please enter a valid amount</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                Select Currency
                            </label>
                            <div className="relative">
                                <select
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                    value={this.state.currency}
                                    onChange={e => this.setState({ currency: e.target.value })}
                                >
                                    <option value="BTC">BTC</option>
                                    <option value="ETH">ETH</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            className="flex items-center justify-center focus:outline-none px-4 py-3 text-white font-light tracking-wider bg-gray-900 rounded w-1/2"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <Spinner /> : 'Get Coins'}
                        </button>
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy;2019 BushaPay. All rights reserved.
                </p>
            </div>
        );
    }
}

export default FaucetForm;